function setupXHRWrapper() {
  if (XMLHttpRequest.prototype.realSend) {
    return
  }

  // Used by wait_for_ajax helper in features tests
  window.tfxAjaxActive = null
  XMLHttpRequest.prototype.realSend = XMLHttpRequest.prototype.send

  XMLHttpRequest.prototype.send = function (body) {
    window.tfxAjaxActive += 1

    this.addEventListener('load', () => (window.tfxAjaxActive -= 1))
    this.addEventListener('error', () => (window.tfxAjaxActive -= 1))

    this.realSend(body)
  }
}

export function setupTestid(app) {
  if (process.env.NODE_ENV === 'production') {
    return
  }

  setupXHRWrapper()

  app.mixin({
    mounted() {
      if (!this.$el?.dataset) return

      if (!this.$el.dataset.test && this.$options.name) {
        // converts the name to kebab-case
        this.$el.dataset.test = this.$options.name.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
      }

      this.$el.dataset.initialized = true
    }
  })
}
