import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['email']

  static values = {
    endpoint: String
  }

  submit(event) {
    event.preventDefault()
    this.save(this.emailTarget.value)
  }

  async save(email) {
    try {
      const response = await fetch(this.endpointValue, {
        method: 'POST',
        body: JSON.stringify({
          form: {
            email
          }
        }),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json'
        }
      })

      if (response) {
        document.getElementById('lead-magnet').outerHTML = await response.text()
        if (response.ok) {
          window.tfxEvent('leed_m', {
            button_id: '1',
            button_name: 'get_free_guide'
          })
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  goToPricing() {
    // until we send an event
    return

    window.tfxEvent('leed_m', {
      button_id: '2',
      button_name: 'see_our_prising'
    })
  }
}
